import {useTranslation} from 'next-i18next'
import {Controller, UseFormReturn} from 'react-hook-form'
import AppSelect from 'src/components/forms/Select'
import {ContactUsData} from 'src/types/contactUs'
import {useGetBookingOptions} from './data'

export default function ExistingBookingOption(props: {
  control: UseFormReturn<ContactUsData>['control']
  requestType: string
  existingBookingOptionError?: string
}) {
  const {control, requestType, existingBookingOptionError} = props

  const {t} = useTranslation('common')
  const bookingOptions = useGetBookingOptions()

  if (requestType === 'i_am_a_guest' || requestType === 'i_am_an_host') {
    return (
      <Controller
        name="existing_booking_option"
        control={control}
        render={({field: {ref, ...others}}) => (
          <AppSelect
            withLabel
            label={t('is_it')}
            options={bookingOptions}
            placeholder={t('type_of_request_placeholder')}
            {...others}
            inputRef={ref}
            error={Boolean(existingBookingOptionError)}
            message={existingBookingOptionError}
          />
        )}
      />
    )
  }

  return null
}
