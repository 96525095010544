import {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'next-i18next'
import clsx from 'clsx'
import {useDispatch, useSelector} from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AppIcon from 'src/components/elements/icons/AppIcon'
import AppTypography from 'src/components/elements/typography/AppTypography'
import DatePickerMenu from 'src/components/elements/DatePickerMenu'
import {useSmallSize} from 'src/hooks/screenSize'
import {getSelectedDateRange} from 'src/store/selectors/propertySearchSelectors'
import {setSelectedDate} from 'src/store/actions/propertySearchActions'
import {useSearchStyles} from 'src/components/modules/home/homeHero/styles'
import {SelectDateProps} from 'src/types/utils'
import {useVisibleMonths} from 'src/utils/calendar'
import {getSimpleFormattedDate} from 'src/hooks/date'
import {useCurrentLocale} from 'src/hooks/locale'
import {DatesStatusMap} from 'src/types/form'
import {getDatesStatusMap} from 'src/components/elements/dateRangeCalendar/data'
import {LOCALE_FR} from 'src/constants/locale'

export default function SearchDate(props: {
  inTopBar?: boolean
  onOpen?: (bool: boolean) => void
}) {
  const {inTopBar, onOpen} = props

  const isSmallSize = useSmallSize()
  const classes = useSearchStyles({inTopBar, isSmallSize})
  const dispatch = useDispatch()
  const reducerDateRange = useSelector(getSelectedDateRange)

  const [dateRange, setDateRange] = useState<SelectDateProps>([
    reducerDateRange?.startDate,
    reducerDateRange?.endDate,
  ])

  const {visibleMonths, setVisibleMonths, nextMonth, previousMonth} =
    useVisibleMonths()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  useEffect(() => {
    //select first month when first render if we have dates
    if (reducerDateRange?.startDate) {
      setVisibleMonths([
        reducerDateRange?.startDate.format('YYYY-MM'),
        reducerDateRange?.startDate.add(1, 'month').format('YYYY-MM'),
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePeriod = (values: SelectDateProps) => {
    setDateRange([values[0], values[1]])
    const newDateRange = {startDate: values[0], endDate: values[1]}
    dispatch(setSelectedDate(newDateRange))
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    if (onOpen) onOpen(true)
  }
  const handleClose = () => {
    setAnchorEl(null)
    if (onOpen) onOpen(false)
  }

  useEffect(() => {
    if (reducerDateRange) {
      setDateRange([reducerDateRange.startDate, reducerDateRange.endDate])
    }
  }, [reducerDateRange])

  //so that dates in the past are disabled
  const datesStatusMap: DatesStatusMap = useMemo(() => {
    return getDatesStatusMap(visibleMonths)
  }, [visibleMonths])

  return (
    <>
      <Grid
        container
        className={clsx(
          classes.flexWrapper,
          classes.searchButtonContainer,
          classes.searchDatesButtonContainer,
        )}
        component={Button}
        onClick={handleClick}
        aria-controls="date-menu"
        aria-haspopup="true"
      >
        <CalendarIcon inTopBar={inTopBar} />
        <Grid item className={classes.inputGrid}>
          <DateText hidden={inTopBar} />
          <Expression dateRange={dateRange} inTopBar={inTopBar} />
        </Grid>
        <ArrowDownIcon visible={inTopBar} />
      </Grid>
      <DatePickerMenu
        datesStatusMap={datesStatusMap}
        visibleMonths={visibleMonths}
        nextMonth={nextMonth}
        previousMonth={previousMonth}
        anchorEl={anchorEl}
        selectedDates={[dateRange[0], dateRange[1]]}
        setSelectedDates={handleChangePeriod}
        handleClose={handleClose}
      />
    </>
  )
}

function DateText(props: {hidden?: boolean}) {
  const {t} = useTranslation('common')

  if (props.hidden) {
    return null
  }

  return (
    <AppTypography
      variant="action"
      neutralColor={600}
      style={{lineHeight: 'normal'}}
    >
      {t('dates')}
    </AppTypography>
  )
}

function ArrowDownIcon(props: {visible?: boolean}) {
  if (!props.visible) {
    return null
  }

  return <AppIcon name="arrow-down" />
}

function CalendarIcon(props: {inTopBar?: boolean}) {
  const {inTopBar} = props

  const isSmallSize = useSmallSize()
  const classes = useSearchStyles({inTopBar, isSmallSize})

  if (props.inTopBar) {
    return null
  }

  return (
    <Grid item className={classes.searchButtonIcon}>
      <AppIcon name="calendar" />
    </Grid>
  )
}

function Expression(props: {dateRange: SelectDateProps; inTopBar?: boolean}) {
  const {dateRange, inTopBar} = props

  const isSmallSize = useSmallSize()
  const classes = useSearchStyles({inTopBar, isSmallSize})
  const dateText = useDateText(dateRange, inTopBar)

  return (
    <AppTypography
      variant="action"
      neutralColor={500}
      className={clsx(
        inTopBar ? classes.truncate : '',
        dateRange[0] && dateRange[1] ? classes.smallFont : '',
      )}
    >
      {dateText}
    </AppTypography>
  )
}

function useDateText(dateRange: SelectDateProps, inTopBar?: boolean) {
  const {t} = useTranslation('common')
  const currentLocale = useCurrentLocale()

  if (!dateRange[0] && !dateRange[1] && inTopBar) {
    return t('dates')
  }

  if (!dateRange[0] && !dateRange[1] && !inTopBar) {
    return t('dates_description')
  }

  if (dateRange[0] && !dateRange[1]) {
    return `${getSimpleFormattedDate(dateRange[0], currentLocale)}`
  }

  let sameYear = dateRange[0]?.format('YYYY') === dateRange[1]?.format('YYYY')
  let sameMonth = dateRange[0]?.format('MM') === dateRange[1]?.format('MM')

  if (dateRange[0] && dateRange[1]) {
    return `${getSimpleFormattedDate(
      dateRange[0],
      currentLocale,
      false,
      sameYear,
      currentLocale === LOCALE_FR && sameMonth, //only remove for french, look weird in en
    )}-${getSimpleFormattedDate(dateRange[1], currentLocale)}`
  }

  return null
}
