import Drawer from '@material-ui/core/Drawer'
import {makeStyles} from '@material-ui/core/styles'
import AppIcon from 'src/components/elements/icons/AppIcon'
import {globalZIndex} from 'src/utils/styles'
import {forwardRef, useEffect, useImperativeHandle, useRef} from 'react'

interface StyleProps {
  fullHeight?: boolean
  customHeight?: string
}

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(
  ({palette, spacing}) => ({
    root: {
      '& .MuiPaper-root': {
        height: (props: StyleProps) =>
          props.fullHeight ? '100%' : props.customHeight || 'auto',
      },
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      borderBottom: `1px solid ${palette.grey[200]}`,
      padding: spacing(2, 0, 2, 2), //little trick to make the X close to the edge so click event work
    },
    titleContainer: {
      display: 'flex',
      gap: spacing(29 / 8),
      alignItems: 'center',
    },
    title: {
      fontWeight: 600,
      fontSize: 18,
    },
    body: {
      height: '100%',
      position: 'relative',
      padding: spacing(3, 0),
      overflowY: 'auto',
      paddingBottom: spacing(60 / 8),
    },
    scrollHide: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      height: '20%',
      background: palette.common.white,
      zIndex: globalZIndex.aboveBase,
    },
  }),
  {index: 2},
)

const SearchDrawer = forwardRef(function (
  props: {
    isOpen: boolean
    onClose: () => void
    fullHeight?: boolean
    title?: string
    children: React.ReactNode
    customHeight?: string
  },
  ref,
) {
  const {isOpen, onClose, fullHeight, title, customHeight, children} = props

  const contentRef = useRef<Element | null>(null)
  const classes = useStyles({fullHeight, customHeight})

  useImperativeHandle(
    ref,
    () => ({
      scrollToTop: () => {
        contentRef.current?.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      },
    }),
    [contentRef],
  )

  useEffect(() => {
    document.body.style.overflowY = isOpen ? 'hidden' : 'initial'
  }, [isOpen])

  return (
    <>
      <Drawer
        anchor={'bottom'}
        open={isOpen}
        className={classes.root}
        onClose={onClose}
        transitionDuration={{
          enter: 125,
          exit: 125,
        }}
      >
        <div className={classes.headerContainer}>
          <div className={classes.titleContainer}>
            <span className={classes.title}>{title}</span>
          </div>
          <div
            className="px-4 flex flex-col justify-center items-center"
            onClick={onClose}
          >
            <AppIcon className="w-3 h-3 block" name="cross" />
          </div>
        </div>

        <div
          ref={(node) => (contentRef.current = node)}
          className={classes.body}
        >
          {children}
        </div>
      </Drawer>
      {isOpen && <div className={classes.scrollHide} />}
    </>
  )
})

export default SearchDrawer
