import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'next-i18next'
import clsx from 'clsx'
import {useSelector, useDispatch} from 'react-redux'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AppIcon from 'src/components/elements/icons/AppIcon'
import AppTypography from 'src/components/elements/typography/AppTypography'
import GuestSelectMenu from 'src/components/elements/GuestSelectMenu'
import {useSmallSize} from 'src/hooks/screenSize'
import {GuestSelectProps} from 'src/types/guestSelect'
import {
  getSelectedGuests,
  getSelectedLocation,
} from 'src/store/selectors/propertySearchSelectors'
import {
  setSelectedGuests,
  setSortByFilter,
} from 'src/store/actions/propertySearchActions'
import {useSearchStyles} from './styles'

export default function GuestsMenu(props: {
  inTopBar?: boolean
  onOpen?: (bool: boolean) => void
}) {
  const {inTopBar, onOpen} = props

  const isSmallSize = useSmallSize()
  const classes = useSearchStyles({inTopBar, isSmallSize})
  const dispatch = useDispatch()
  const selectedLocation = useSelector(getSelectedLocation)
  const guests = useSelector(getSelectedGuests)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [guestCount, setGuestCount] = useState<number>(0)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    if (onOpen) onOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (onOpen) onOpen(false)
  }

  const handleApply = useCallback(() => {
    const adultCount = guests?.adults ? Number(guests.adults) : 0
    const childrenCount = guests?.children ? Number(guests.children) : 0

    const guestCount = adultCount + childrenCount
    setGuestCount(guestCount)
  }, [guests.adults, guests.children])

  const handleChangeGuest = (guests: GuestSelectProps) => {
    dispatch(setSelectedGuests(guests))

    //only select relevance if empty address
    if (!selectedLocation.address) {
      dispatch(setSortByFilter('relevance'))
    }
  }

  useEffect(() => {
    handleApply()
  }, [handleApply])

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        className={clsx(
          classes.flexWrapper,
          classes.searchButtonContainer,
          classes.guestContainer,
        )}
        style={{width: 192}}
        component={Button}
        onClick={handleClick}
        aria-controls="guest_selection"
        aria-haspopup="true"
      >
        <GuestIcon inTopBar={inTopBar} />
        <Grid item className={classes.inputGrid}>
          <GuestText hidden={inTopBar && guestCount > 0} inTopBar={inTopBar} />
          <GuestExpression guestCount={guestCount} inTopBar={inTopBar} />
          <ArrowDownIcon visible={inTopBar} />
        </Grid>
      </Grid>
      <GuestSelectMenu
        anchorEl={anchorEl}
        guests={guests}
        handleClose={handleClose}
        onChange={handleChangeGuest}
      />
    </>
  )
}

function GuestIcon(props: {inTopBar?: boolean}) {
  const {inTopBar} = props
  const isSmallSize = useSmallSize()
  const classes = useSearchStyles({inTopBar, isSmallSize})

  if (inTopBar) {
    return null
  }

  return (
    <Grid item className={classes.searchButtonIcon}>
      <AppIcon name="guests" />
    </Grid>
  )
}

function GuestText(props: {hidden?: boolean; inTopBar?: boolean}) {
  const {t} = useTranslation('common')

  const getStyles = () => {
    if (props.inTopBar) {
      return {lineHeight: 'normal', padding: '4px 0'}
    }

    return {lineHeight: 'normal'}
  }

  if (props.hidden) {
    return null
  }

  return (
    <AppTypography variant="action" neutralColor={600} style={getStyles()}>
      {t('guests')}
    </AppTypography>
  )
}

function ArrowDownIcon(props: {visible?: boolean}) {
  if (!props.visible) {
    return null
  }

  return <AppIcon name="arrow-down" />
}

function GuestExpression(props: {guestCount: number; inTopBar?: boolean}) {
  const {guestCount, inTopBar} = props
  const isSmallSize = useSmallSize()
  const classes = useSearchStyles({inTopBar, isSmallSize})
  const guestsExp = useGuestExpression(guestCount, inTopBar)

  //todo convert to useMemo
  const getClassName = () => {
    if (inTopBar) {
      return clsx(classes.truncate, classes.smallFont, classes.guestLabel)
    }

    return ''
  }

  return (
    <AppTypography
      variant="action"
      neutralColor={500}
      className={getClassName()}
    >
      {guestsExp}
    </AppTypography>
  )
}

const useGuestExpression = (guestCount: number, inTopBar?: boolean) => {
  const {t} = useTranslation('common')

  if (guestCount === 0 && inTopBar) {
    return null
  }

  if (guestCount === 0) {
    return t('guests_description')
  }

  if (guestCount === 1) {
    return `${guestCount} ${t('guest')}`
  }

  return `${guestCount} ${t('guests')}`
}
