import {useTranslation} from 'next-i18next'
import {makeStyles} from '@material-ui/core/styles'
import {GuestSelectProps} from 'src/types/guestSelect'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(
  ({spacing}) => ({
    container: {
      display: 'flex',
      width: '100%',
      gap: spacing(3.5),
      lineHeight: 1.5,
    },
    label: {
      fontSize: 8,
    },
    value: {
      fontSize: 10,
    },
    itemContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
  {index: 2},
)

export default function GuestField(props: {guests: GuestSelectProps}) {
  const {guests} = props

  const classes = useStyles()
  const {t} = useTranslation('common')

  const translations: {
    [key in keyof typeof guests]: string
  } = {
    adults: `${t('adults')}s`,
    children: t('children'),
    infants: `${t('infants')}s`,
    animals: t('animals'),
  }

  return (
    <div className={classes.container}>
      {(Object.keys(guests) as Array<keyof GuestSelectProps>)?.map((key) => {
        const value = guests[key]

        return (
          <div key={key} className={classes.itemContainer}>
            <div className={classes.label}>
              {translations[key].toUpperCase()}
            </div>
            <div className={classes.value}>{value}</div>
          </div>
        )
      })}
    </div>
  )
}
