import {makeStyles} from '@material-ui/core/styles'

interface StyleProps {
  elivated?: boolean
}

export const useStyles = makeStyles(
  ({palette, spacing}) => ({
    container: {
      backgroundColor: palette.common.white,
      borderRadius: spacing(2),
      boxShadow: ({elivated}: StyleProps) =>
        elivated ? ' 0px 40px 96px -24px rgba(49, 50, 94, 0.12)' : 'auto',
      padding: spacing(2.5),
    },
    inputs: {
      width: '100%',
    },
    handleSearchMobile: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: spacing(10 / 8, 20 / 8),
      width: '100%',
      height: 44,
      marginTop: spacing(1),
      // background: palette.primary.main,
      borderRadius: spacing(3),
    },
  }),
  {index: 2},
)
