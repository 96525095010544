import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(({breakpoints, spacing}) => ({
  container: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(98 / 8, 0),
  },
  image: {
    borderRadius: 0,
    height: '100vh',
    width: '50%',
    objectFit: 'cover',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: breakpoints.values.sm,
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: spacing(7, 3),
    [breakpoints.down('xs')]: {
      padding: spacing(0, 3),
    },
  },
  form: {
    marginTop: spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
}))
