import SearchDrawer from 'src/components/modules/home/homeHero/mobileSearch/SearchDrawer'
import MobileSearch from 'src/components/modules/home/homeHero/mobileSearch/MobileSearch'
import {useTranslation} from 'next-i18next'

export default function SearchSidebar(props: {
  open: boolean
  onClose: () => void
}) {
  const {open, onClose} = props
  const {t} = useTranslation(['common'])

  return (
    <SearchDrawer
      isOpen={open}
      onClose={onClose}
      title={t('search')}
      fullHeight
    >
      <MobileSearch onSearched={onClose} />
    </SearchDrawer>
  )
}
