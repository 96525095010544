import {useTranslation} from 'next-i18next'
import {makeStyles} from '@material-ui/core/styles'
import {AmenitiesSection} from 'src/components/modules/home/homeHero/searchComponents/SearchAmenities'
import AppButton from 'src/components/elements/buttons/AppButton'
import {BaseStepProps} from 'src/components/modules/home/homeHero/mobileSearch/MobileSearch'
import {globalZIndex} from 'src/utils/styles'
import {popularAmenities} from 'src/constants/amenitiesDisplayList'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(
  ({palette, spacing}) => ({
    container: {
      listStyle: 'none',
      position: 'relative',
    },
    footer: {
      position: 'fixed',
      display: 'flex',
      width: '100%',
      bottom: 0,
      padding: spacing(0, 20 / 8, 20 / 8, 20 / 8),
      left: 0,
      background: palette.common.white,
      zIndex: globalZIndex.moreAboveBase,
    },
  }),
  {index: 2},
)

export default function Filter({changeStep}: BaseStepProps) {
  const {t} = useTranslation('common')
  const classes = useStyles()

  const handleDoneClick = () => changeStep(null)

  return (
    <div className={classes.container}>
      <AmenitiesSection filteredSlugs={popularAmenities} />
      <div className={classes.footer}>
        <AppButton
          children={t('done')}
          variant={'contained'}
          color={'primary'}
          onClick={handleDoneClick}
          style={{
            width: '100%',
            height: 40,
            borderRadius: 24,
            marginLeft: 8,
            marginRight: 8,
            marginTop: 10,
          }}
        />
      </div>
    </div>
  )
}
