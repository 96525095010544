import {useTranslation} from 'next-i18next'
import {UseFormReturn} from 'react-hook-form'
import AppTextField from 'src/components/forms/textField/AppTextField'
import {ContactUsData} from 'src/types/contactUs'

export default function ReservationNumber(props: {
  register: UseFormReturn<ContactUsData>['register']
  requestType: string
  existingBookingOption: string
  reservationNumberError?: string
}) {
  const {requestType, existingBookingOption, reservationNumberError, register} =
    props

  const {t} = useTranslation(['common'])

  if (requestType !== 'i_am_a_guest' && requestType !== 'i_am_an_host') {
    return null
  }

  if (existingBookingOption !== 'has_existing_booking') {
    return null
  }

  return (
    <AppTextField
      inputProps={register('reservation_number', {
        required: 'Reservation Number is required',
      })}
      label={t('reservation_number')}
      placeholder={t('reservation_number')}
      error={Boolean(reservationNumberError)}
      message={
        reservationNumberError ? t(reservationNumberError, {ns: 'common'}) : ''
      }
    />
  )
}
