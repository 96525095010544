import {useState} from 'react'
import {useTranslation} from 'next-i18next'
import {makeStyles} from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import AppTypography from 'src/components/elements/typography/AppTypography'
import AppIcon from 'src/components/elements/icons/AppIcon'
import AppButton from 'src/components/elements/buttons/AppButton'
import ArrowDownIcon from 'src/components/elements/icons/ArrowDown'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'
import ArrowUpIcon from 'src/components/elements/icons/ArrowUp'
import ContactUsModal from 'src/components/modules/contactUs/ContactUsModal'

export default function ContactUsMenuPopUp(props: {isTop?: boolean}) {
  const {isTop} = props

  const {t} = useTranslation('header')

  const classes = useStyles({isTop})

  const [showContactUsModal, setShowContactUsModal] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)

  const handlePhoneCall = () => {
    handleCloseMenu()
    window.open('tel:+18446242538')
  }
  const handleEmail = () => {
    handleCloseMenu()
    window.open('mailto:info@monsieurchalets.com')
  }
  const handleWriteUs = () => {
    handleCloseMenu()
    setShowContactUsModal(true)
    //let url = currentLocale === LOCALE_EN ? '/contact-us' : '/contacteznous'
    //router.push(url)
  }
  const handleQuestions = () => {
    handleCloseMenu()
    window.open('https://help.monsieurchalets.com/fr/', '_blank')
  }

  return (
    <>
      <AppButton
        variant="outlined"
        onClick={handleOpenMenu}
        className={classes.transparentBtn}
      >
        <div className="flex flex-row justify-start items-center gap-2">
          <span>{t('contact_us2')}</span>
          {anchorEl ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </div>
      </AppButton>
      <Menu
        className={classes.menuWrapper}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="w-64 p-3">
          <div className="divide-y divide-divider flex flex-col justify-start items-start gap-1 w-full">
            <MenuItem
              icon="inbox"
              onClick={handleWriteUs}
              label={t('contact_us')}
            />
            <MenuItem
              icon="info-gray"
              onClick={handleQuestions}
              label={t('help_center')}
            />

            <MenuItem
              icon="at-sign"
              onClick={handleEmail}
              label={'info@monsieurchalets.com'}
            />

            <MenuItem
              icon="phone"
              onClick={handlePhoneCall}
              label={'1-844-624-2538'}
            >
              <AppTypography className="text-left" variant="caption">
                {t('opening_time')}
              </AppTypography>
            </MenuItem>
          </div>
        </div>
      </Menu>

      <ContactUsModal
        open={showContactUsModal}
        onClose={() => setShowContactUsModal(false)}
      />
    </>
  )
}

function MenuItem(props: {
  icon: string
  label: string
  onClick: () => void
  children?: React.ReactNode
}) {
  return (
    <div className="w-full pt-1">
      <AppRoundedButton
        variant="text"
        type="button"
        onClick={props.onClick}
        className="w-full cursor-pointer h-12"
      >
        <div className="w-full flex flex-row justify-start items-center gap-3 cursor-pointer h-12">
          <div className="flex flex-col justify-center items-center w-[16px] h-[16px] min-w-[16px]">
            <AppIcon name={props.icon} />
          </div>
          <div className="flex flex-col justify-start items-start">
            <AppTypography variant="action" neutralColor={800}>
              {props.label}
            </AppTypography>
            {props.children}
          </div>
        </div>
      </AppRoundedButton>
    </div>
  )
}

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({spacing, palette, shadows}) => ({
  menuWrapper: {
    '& .MuiPopover-paper': {
      marginTop: spacing(1),
      boxShadow: shadows[2],
    },
  },
  transparentBtn: ({isTop}: {isTop?: boolean}) => ({
    background: isTop ? 'transparent' : undefined,
    height: '40px',
    //borderColor: isTop ? palette.text.primary : undefined,
    //color: isTop ? palette.text.primary : undefined,
    padding: spacing(1, 2.5),
    '& span': {
      lineHeight: 'normal',
    },
  }),
}))
