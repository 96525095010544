import {makeStyles} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

interface StyleProps {
  fluid?: boolean
}

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({breakpoints, spacing}) => ({
  container: ({fluid}: StyleProps) => ({
    paddingLeft: spacing(fluid ? 3 : 0),
    paddingRight: spacing(fluid ? 3 : 0),
    ...(fluid && {maxWidth: 'unset', width: '92%'}),
    [breakpoints.down('lg')]: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  }),
}))

export default function AppBaseContainer(props: {
  children: React.ReactElement
  fluid?: boolean
}) {
  const {children, fluid} = props
  const classes = useStyles({fluid})

  return (
    <Container disableGutters className={classes.container} fixed>
      {children}
    </Container>
  )
}
