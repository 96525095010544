import {useTranslation} from 'next-i18next'
import {RequestTypeOption} from 'src/types/contactUs'

const requestTypes: RequestTypeOption[] = [
  {
    label: 'i_am_a_guest',
    value: 'i_am_a_guest',
  },
  {
    label: 'i_am_an_host',
    value: 'i_am_an_host',
  },
  {
    label: 'general_question',
    value: 'general_question',
  },
  {
    label: 'marketing_request',
    value: 'marketing_request',
  },
  {
    label: 'career_request',
    value: 'career_request',
  },
  {
    label: 'others',
    value: 'others',
  },
]

export const existingBookingOptions = [
  {
    label: 'Yes',
    value: 'has_existing_booking',
  },
  {
    label: 'No',
    value: 'no',
  },
]

export const useGetRequestTypes = () => {
  const {t} = useTranslation('common')

  return requestTypes.map((type) => {
    return {
      ...type,
      label: t(type.label),
    }
  })
}

export const getReservationNumber = (
  requestType: any,
  existingBookingOption: any,
  reservationNumber: any,
) => {
  if (requestType !== 'i_am_a_guest' && requestType !== 'i_am_an_host') {
    return ''
  }

  if (existingBookingOption !== 'has_existing_booking') {
    return ''
  }

  return reservationNumber
}

export const useGetBookingOptions = () => {
  const {t} = useTranslation('common')

  return existingBookingOptions.map((option) => {
    return {
      ...option,
      label: t(option.label),
    }
  })
}
