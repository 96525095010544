import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'next-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {makeStyles} from '@material-ui/core/styles'
import DateRangePicker from 'src/components/elements/DateRangePicker'
import AppButton from 'src/components/elements/buttons/AppButton'
import {getSelectedDateRange} from 'src/store/selectors/propertySearchSelectors'
import {setSelectedDate} from 'src/store/actions/propertySearchActions'
import {BaseStepProps} from 'src/components/modules/home/homeHero/mobileSearch/MobileSearch'
import {useSmallerSize, useSmallSize} from 'src/hooks/screenSize'
import {SelectDateProps} from 'src/types/utils'
import {useVisibleMonths} from 'src/utils/calendar'
import {globalZIndex} from 'src/utils/styles'
import {DatesStatusMap} from 'src/types/form'
import {getDatesStatusMap} from 'src/components/elements/dateRangeCalendar/data'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(
  ({palette, spacing}) => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: spacing(3),
      position: 'relative',
    },
    footer: {
      position: 'fixed',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gridGap: '12px',
      width: '100%',
      bottom: 0,
      padding: spacing(0, 20 / 8, 20 / 8, 20 / 8),
      left: 0,
      background: palette.common.white,
      zIndex: globalZIndex.moreAboveBase,
    },
  }),
  {index: 2},
)

const FLEXIBLE_BUTTON_STYLES: React.CSSProperties = {
  position: 'absolute',
  top: -10,
  right: 16,
  height: 40,
  borderRadius: 24,
  padding: 10,
  fontSize: 12,
}

export default function DateStep({changeStep, scrollToTop}: BaseStepProps) {
  const {t} = useTranslation('common')
  const classes = useStyles()
  const reducerDateRange = useSelector(getSelectedDateRange)
  const dispatch = useDispatch()
  const isSmallerSize = useSmallerSize()
  const isSmallSize = useSmallSize()

  const [dateRange, setDateRange] = useState([
    reducerDateRange?.startDate,
    reducerDateRange?.endDate,
  ])

  const {visibleMonths, setVisibleMonths, nextMonth, previousMonth} =
    useVisibleMonths()

  useEffect(() => {
    //select first month when first render if we have dates
    if (reducerDateRange?.startDate) {
      setVisibleMonths([
        reducerDateRange?.startDate.format('YYYY-MM'),
        reducerDateRange?.startDate.add(1, 'month').format('YYYY-MM'),
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePeriod = (values: SelectDateProps) => {
    setDateRange([values[0], values[1]])
    const newDateRange = {startDate: values[0], endDate: values[1]}
    dispatch(setSelectedDate(newDateRange))
  }

  const handleCancelClick = () => {
    setDateRange([null, null])
    dispatch(setSelectedDate({startDate: null, endDate: null}))
    changeStep(null)
  }

  const handleDoneClick = () => changeStep('guests')

  //so that dates in the past are disabled
  const datesStatusMap: DatesStatusMap = useMemo(() => {
    return getDatesStatusMap(visibleMonths)
  }, [visibleMonths])

  return (
    <div className={classes.container}>
      <DateRangePicker
        datesStatusMap={datesStatusMap}
        visibleMonths={visibleMonths}
        nextMonth={nextMonth}
        previousMonth={previousMonth}
        disableSmallSize
        vertical={isSmallerSize}
        selectedDates={[dateRange[0], dateRange[1]]}
        setSelectedDates={handleChangePeriod}
        onFlexibleDates={() => {}}
        changeOnSelect={isSmallSize}
        disableButtons={isSmallSize}
        scrollToTop={scrollToTop}
        flexibleButtonStyles={FLEXIBLE_BUTTON_STYLES}
      />
      <div className={classes.footer}>
        <AppButton
          children={t('clear')}
          variant="outlined"
          color="primary"
          onClick={handleCancelClick}
          style={{
            width: '50%',
            height: 40,
            borderRadius: 24,
          }}
        />
        <AppButton
          children={t('done')}
          variant={'contained'}
          color={'primary'}
          onClick={handleDoneClick}
          style={{
            width: '50%',
            height: 40,
            borderRadius: 24,
          }}
        />
      </div>
    </div>
  )
}
