import {FieldError, Resolver} from 'react-hook-form'
import isEmpty from 'validator/lib/isEmpty'
import isLength from 'validator/lib/isLength'
import {
  validateEmail,
  validateName,
  validateRequired,
} from 'src/services/validation/validateModules'
import {ContactUsData} from 'src/types/contactUs'

const validate: Omit<
  Record<keyof ContactUsData, (value: string) => string | undefined>,
  | 'existing_booking_option'
  | 'reservation_number'
  | 'phone'
  | 'destination'
  | 'start_date'
  | 'end_date'
  | 'honeypot'
> = {
  name: validateName('Name'),
  email: validateEmail,
  request_type: validateRequired('Request type'),
  message: validateMessage('Message'),
}

function getMessage(values: ContactUsData, key: keyof ContactUsData) {
  const {
    request_type: requestType,
    existing_booking_option: existingBookingOption,
    reservation_number: reservationNumber,
  } = values
  if (key === 'existing_booking_option') {
    return validateExistingBookingOption(requestType, existingBookingOption)
  }

  if (key === 'reservation_number') {
    return validateReservationNumber(existingBookingOption, reservationNumber)
  }
  // @ts-ignore
  if (typeof validate[key] === 'undefined') {
    return null
  }

  // @ts-ignore
  return validate[key](values[key])
}

export const formResolver: Resolver<ContactUsData> = async (
  values: ContactUsData,
) => {
  const errors: Record<string, FieldError> = {}

  for (let field in values) {
    const key = field as keyof ContactUsData
    const message = getMessage(values, key)
    if (message) {
      errors[key] = {
        type: '',
        message,
      }
    }
  }

  return {
    errors,
    values,
  }
}

function validateMessage(field: string) {
  return (value: string) => {
    if (isEmpty(value)) {
      return `${field} is required`
    }

    if (!isLength(value, {max: 300})) {
      return `${field} should be less than 300 letters`
    }

    return undefined
  }
}

function validateExistingBookingOption(
  requestType: ContactUsData['existing_booking_option'],
  existingBookingOption: string | null,
) {
  if (
    (requestType === 'i_am_a_guest' || requestType === 'i_am_an_host') &&
    !existingBookingOption
  ) {
    return 'Required'
  }

  return undefined
}

function validateReservationNumber(
  existingBookingOption: string,
  reservationNumber: string,
) {
  if (existingBookingOption === 'has_existing_booking' && !reservationNumber) {
    return 'Reservation number is required'
  }

  return undefined
}
