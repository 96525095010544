import {useRouter} from 'next/router'
import {useDispatch} from 'react-redux'
import clsx from 'clsx'
import AppButton from 'src/components/elements/buttons/AppButton'
import AppIcon from 'src/components/elements/icons/AppIcon'
import SearchAmenities from 'src/components/modules/home/homeHero/searchComponents/SearchAmenities'
import LocationSearchBox from 'src/components/modules/locationSearchBox/LocationSearchBox'
import GuestsMenu from './GuestsMenu'
import SearchDate from 'src/components/modules/home/homeHero/searchComponents/SearchDate'
import {
  fetchSearchData,
  setClickedPropertyUid,
  setScrollY,
  setSearchPageNo,
} from 'src/store/actions/propertySearchActions'
import {useSmallSize} from 'src/hooks/screenSize'
import {SEARCH_RESULT_PAGE, SEARCH_RESULT_PAGE_EN} from 'src/constants/route'
import {useSearchTopStyles} from './styles'
import {useCurrentLocale} from 'src/hooks/locale'
import {LOCALE_EN} from 'src/constants/locale'

export default function SearchBoxInTop(props: {inTopBar?: boolean}) {
  const {inTopBar} = props

  const isSmallSize = useSmallSize()
  const classes = useSearchTopStyles({inTopBar: false, isSmallSize})
  const router = useRouter()
  const dispatch = useDispatch()

  const currentLocale = useCurrentLocale()
  const searchPage =
    currentLocale === LOCALE_EN ? SEARCH_RESULT_PAGE_EN : SEARCH_RESULT_PAGE

  const handleSearch = () => {
    dispatch(setSearchPageNo(1))
    dispatch(fetchSearchData(1, false, true, router))

    dispatch(setScrollY(0))
    dispatch(setClickedPropertyUid(null))

    if (router.route === searchPage) {
      return
    }

    router.push(searchPage)
  }

  return (
    <div
      className={clsx(
        classes.root,
        classes.searchMenuContainer,
        inTopBar ? classes.headerSearchScroll : undefined,
      )}
    >
      <div
        className={clsx(classes.flexWrapper, classes.inputAutoCompleteInTop)}
        style={{
          maxWidth: isSmallSize ? '100%' : 180,
          padding: isSmallSize ? '0 15px' : undefined,
        }}
      >
        <IconLocation inTopBar={inTopBar} />
        <LocationSearchBox inTopBar={inTopBar} />
      </div>
      <div className={classes.searchMenuButtonContainer}>
        <SearchDate inTopBar={inTopBar} />
      </div>
      <div className={clsx(classes.searchMenuButtonContainer, 'ml-0')}>
        <GuestsMenu inTopBar={inTopBar} />
      </div>
      <AmenitiesSearch inTopBar={inTopBar} />
      <AppButton
        variant="contained"
        className={clsx(
          classes.searchPrimaryBtnInTop,
          classes.searchButtonStyle,
        )}
        color="secondary"
        onClick={handleSearch}
      >
        <IconSearch inTopBar={false} />
      </AppButton>
    </div>
  )
}

function IconLocation(props: {inTopBar?: boolean}) {
  const {inTopBar} = props

  const isSmallSize = useSmallSize()
  const classes = useSearchTopStyles({inTopBar, isSmallSize})

  if (inTopBar) {
    return null
  }

  return <AppIcon name="location" className={classes.locationIcon} />
}

function IconSearch(props: {inTopBar?: boolean}) {
  if (props.inTopBar) {
    return <AppIcon name="search_other" />
  }

  return <AppIcon name="search" />
}

function AmenitiesSearch(props: {inTopBar?: boolean}) {
  const {inTopBar} = props

  const isSmallSize = useSmallSize()
  const classes = useSearchTopStyles({inTopBar: inTopBar, isSmallSize})

  if (inTopBar) {
    return null
  }

  return (
    <div className={classes.searchMenuButtonContainer}>
      <SearchAmenities />
    </div>
  )
}
